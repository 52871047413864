@tailwind base;
@tailwind components;
@tailwind utilities;

@import "fonts";

html, body {
  
  @apply min-h-full;
}

body {
  font-family: "OpenSans", sans-serif;
  @apply text-textColor;
  //@apply bg-background;
  @apply bg-gradient-to-br from-gray-950 to-black bg-cover bg-no-repeat;
}